import React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import MainTitle from '../components/uielements/MainTitle'
import Spacer from '../components/uielements/Spacer'
import styled from 'styled-components'
import FlexWrapper from '../components/uielements/flexWrapper'
import Flex from '../components/uielements/Flex'
import Wrapper from '../components/Wrapper'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'

const ProgramBox = styled.div`
    display: block;
    box-shadow: 0 0 14px #ccc;
    margin: 30px 20px;
    text-align: center;
    color: #fff;
    font-size: 1.2em;
    border-radius: 6px;
    position: relative;

    .programFor {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 14px;
        text-transform: uppercase;

        span {
            color: ${({ theme }) => theme.blue};
            margin-right: 10px;
        }
    }

    a {
        color: ${({ theme }) => theme.blue};
        border: 4px solid ${({ theme }) => theme.blue};
        padding: 50px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-decoration: none;
        border-radius: 6px;
        height: 200px;

        &:hover {
            background: ${({ theme }) => theme.blue};
            color: #fff;
        }
    }
`

const ProgramsPage = ({ data: { programs } }) => {
    const { t, i18n } = useTranslation()

    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={t('programs')} />
            </Helmet>
            <Wrapper>
                <Seo title={t('programs')} />
                <MainTitle>{t('programs')}</MainTitle>
                <Spacer top="3" />
                <FlexWrapper xl={{ margin: '0 -10px 0 -10px' }}>
                    {programs.nodes.map(
                        program =>
                            i18n.language === program.node_locale && (
                                <Flex
                                    flexBasis="33%"
                                    key={program.id}
                                    sm={{ flexBasis: '100%' }}
                                    md={{ flexBasis: '50%' }}
                                    lg={{ flexBasis: '50%' }}
                                >
                                    <ProgramBox>
                                        <div className="programFor">
                                            {program.programFor &&
                                                program.programFor.map(pro => (
                                                    <span>{t(pro)}</span>
                                                ))}
                                        </div>
                                        <Link
                                            title={program.title}
                                            to={`/program/${program.slug}`}
                                            key={program.id}
                                        >
                                            {program.title}
                                        </Link>
                                    </ProgramBox>
                                </Flex>
                            )
                    )}
                </FlexWrapper>
            </Wrapper>
        </Layout>
    )
}

export const query = graphql`
    query programsQuery {
        programs: allContentfulPrograms {
            nodes {
                id
                slug
                title
                node_locale
                programFor
            }
        }
    }
`

export default ProgramsPage
